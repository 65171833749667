import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PARTNER_EQUATIV } from './constants';
import { CtvSegmentResponse, CtvSegmentSave, CtvSegmentState, CtvSegmentUpdate } from './types';

export const CTV_SEGMENT_SLICE = 'ctv_segment_slice';

const initialState: CtvSegmentState = {
  id: '',
  name: '',
  partner: PARTNER_EQUATIV.key,
  seatId: '',
  expiredDate: new Date().toISOString(),
  group: null,
  publicSegment: false,
  parentId: null,
  hideNameForPartner: false,
  ctvSegmentToDeleteId: '',
  targetCombinations: [],
  targetCombinationsForApi: [],
  dataOnly: false,

  isLoading: false,
  isCreated: false,
  failMassage: '',
};

export const ctvSegmentSlice = createSlice({
  name: CTV_SEGMENT_SLICE,
  initialState,
  reducers: {
    deleteCtvSegment: (state: CtvSegmentState) => {
      return {
        ...state,
        ...{ isLoading: true },
      };
    },
    deleteCtvSegmentSuccess: (state: CtvSegmentState) => {
      return {
        ...state,
        ...{ isLoading: false },
        ...{ ctvSegmentToDeleteId: '' }, // this will replace ctvSegmentToDelete in state obj
        ...{ isCreated: false },
      };
    },
    deleteCtvSegmentFailure: (
      state: CtvSegmentState,
      { payload }: PayloadAction<{ message: string }>,
    ) => {
      return {
        ...state,
        ...{ isLoading: false },
        ...{ failMassage: payload.message },
        ...{ isCreated: false },
      };
    },
    setCtvSegmentUpdateId: (state: CtvSegmentState, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        ...{ id: payload },
      };
    },
    setCtvSegmentToDeleteId: (state: CtvSegmentState, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        ...{ ctvSegmentToDeleteId: payload },
      };
    },
    saveCtvSegment: (state: CtvSegmentState, { payload }: PayloadAction<CtvSegmentSave>) => {
      return {
        ...state,
        ...{ isLoading: true },
        ...payload,
      };
    },
    saveCtvSegmentFailed: (state: CtvSegmentState, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        ...{ isLoading: false, failMassage: payload },
        ...{ isCreated: false },
      };
    },
    saveCtvSegmentSuccess: (
      state: CtvSegmentState,
      { payload }: PayloadAction<CtvSegmentResponse>,
    ) => {
      return {
        ...state,
        ...payload,
        ...{ isLoading: false },
        ...{ isCreated: true },
        ...{ group: payload.group.id },
      };
    },
    getCtvSegmentById: (state: CtvSegmentState, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        ...{ id: payload },
        ...{ isLoading: true },
      };
    },
    getCtvSegmentByIdSuccess: (
      state: CtvSegmentState,
      { payload }: PayloadAction<CtvSegmentResponse>,
    ) => {
      return {
        ...state,
        ...payload,
        ...{ group: payload.group.id },
        ...{ isLoading: false },
        ...{ isCreated: false },
      };
    },
    getCtvSegmentByIdFailure: (state: CtvSegmentState, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        ...{ failMassage: payload },
        ...{ isLoading: false },
      };
    },
    updateCtvSegment: (state: CtvSegmentState, { payload }: PayloadAction<CtvSegmentUpdate>) => {
      return {
        ...state,
        ...payload,
        ...{ isLoading: true },
      };
    },
    updateCtvSegmentSuccess: (
      state: CtvSegmentState,
      { payload }: PayloadAction<CtvSegmentResponse>,
    ) => {
      return {
        ...state,
        ...payload,
        ...{ group: payload.group.id },
        ...{ isLoading: false },
        ...{ isCreated: true },
      };
    },
    updateCtvSegmentFailure: (state: CtvSegmentState, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        ...{ failMassage: payload },
        ...{ isLoading: false },
        ...{ isCreated: false },
      };
    },
    resetCtvSegmentState: () => {
      return { ...initialState };
    },
  },
});

export const {
  deleteCtvSegment,
  setCtvSegmentToDeleteId,
  deleteCtvSegmentFailure,
  setCtvSegmentUpdateId,
  deleteCtvSegmentSuccess,
  saveCtvSegment,
  saveCtvSegmentFailed,
  saveCtvSegmentSuccess,
  getCtvSegmentById,
  getCtvSegmentByIdFailure,
  getCtvSegmentByIdSuccess,
  updateCtvSegment,
  updateCtvSegmentSuccess,
  updateCtvSegmentFailure,
  resetCtvSegmentState,
} = ctvSegmentSlice.actions;

export const ctvSegmentReducer = ctvSegmentSlice.reducer;
