import { Skeleton } from 'antd';
import { HierarchyNode } from 'd3-hierarchy';
import { XIcon } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button } from 'components/common';

import { intentEstimateOrgStart } from './IntentEstimateSlice';
import { getIntentEstimateOrgState } from './intentEstimateSelectors';
import { OrgChartComponent } from './org/OrgChart';
import { PanelContent } from './org/panels/PanelContent';
import { OrgAllNodes } from './types';

export const IntentEstimateOrg = () => {
  const dispatch = useDispatch();
  const [panelVisible, setLeftPanelVisible] = useState(false);
  const [nodeData, setNodeData] = useState<OrgAllNodes | null>(null);
  const cRef = useRef<HTMLDivElement>(null);

  const { isLoading, data } = useSelector(getIntentEstimateOrgState);

  const { id } = useParams();

  const chartClickHandler = useCallback(
    (node: HierarchyNode<OrgAllNodes>) => {
      setNodeData(node.data);
      if (nodeData && panelVisible && node.data.id === nodeData.id) {
        setLeftPanelVisible(false);
      } else {
        setLeftPanelVisible(true);
      }
    },
    [nodeData, panelVisible],
  );

  useEffect(() => {
    if (id && data === null) {
      dispatch(intentEstimateOrgStart({ segmentId: id }));
    }
  }, [id, dispatch, data]);

  return (
    <>
      {!isLoading && data !== null && id && (
        <div className="flex h-full">
          <div className="flex-1 h-full" ref={cRef}>
            <OrgChartComponent
              segmentId={id}
              dataFromApi={data}
              onClick={(node) => chartClickHandler(node)}
              isPanelVisible={panelVisible}
            ></OrgChartComponent>
          </div>

          <div
            className={`overflow-y-auto border-l border-nano-pca-green border-opacity-25 max-w-2xl fixed right-0 bg-secondary-white top-0 h-full transition-transform duration-300 ease-in-out ${
              panelVisible ? 'transform translate-x-0' : 'transform translate-x-full'
            }`}
          >
            <div className="border-b flex flex-row border-nano-pca-green border-opacity-25">
              <div className="bborder flex grow justify-end">
                <Button variant="text" onClick={() => setLeftPanelVisible(false)}>
                  <XIcon />
                </Button>
              </div>
            </div>

            {nodeData && <PanelContent node={nodeData} />}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="flex h-full p-6">
          <Skeleton active />
        </div>
      )}
    </>
  );
};
