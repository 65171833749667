import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import {
  CtvApp,
  CtvGenre,
  CtvIntentPersona,
  CtvPlatform,
  CtvTargetCombination,
  CtvTargetCombinationState,
  CtvTargetingTypes,
} from '../types';
import { createKeyTypeForCtvTargetData } from './utils';

const initialState: CtvTargetCombinationState[] = [
  {
    id: uuid(),
    targets: [],
  },
];

export const ctvTargetCombinationsSlice = createSlice({
  name: 'CTV_TARGET_COMBINATIONS_SLICE',
  initialState,
  reducers: {
    addOneTargetCombination: (
      state: CtvTargetCombinationState[],
      { payload }: PayloadAction<CtvTargetCombinationState>,
    ) => {
      return [...state, payload];
    },
    removeOneTargetCombination: (
      state: CtvTargetCombinationState[],
      { payload }: PayloadAction<string>,
    ) => {
      return [...state.filter((targetCombination) => targetCombination.id !== payload)];
    },
    addTargetTypeToTargetCombination: (
      // Target Types in this case are: genre, intent persona, app and platform
      state: CtvTargetCombinationState[],
      {
        payload,
      }: PayloadAction<{
        targetId: string;
        targetCombinationId: string;
        targetType: CtvTargetingTypes;
      }>,
    ) => {
      const targetDataKey = createKeyTypeForCtvTargetData(payload.targetType);

      return state.map((item) => {
        return item.id === payload.targetCombinationId
          ? {
              ...item,
              targets: [
                ...item.targets,
                {
                  id: payload.targetId,
                  type: payload.targetType,
                  [targetDataKey]: [], // 'genres', 'apps', 'platforms', 'intentPersonas'
                  exclude: false,
                },
              ],
            }
          : item;
      });
    },
    addTargetTypeDataToSpecificTargetInTC: (
      state: CtvTargetCombinationState[],
      {
        payload,
      }: PayloadAction<{
        targetId: string;
        targetCombinationId: string;
        dataObj: CtvGenre | CtvApp | CtvPlatform | CtvIntentPersona;
        targetType: CtvTargetingTypes;
      }>,
    ) => {
      const targetDataKey = createKeyTypeForCtvTargetData(payload.targetType);

      return state.map((targetCombination) => {
        if (targetCombination.id === payload.targetCombinationId) {
          return {
            ...targetCombination,
            targets: targetCombination.targets.map((target) => {
              if (target.id === payload.targetId && target[targetDataKey] !== undefined) {
                if (
                  target[targetDataKey]?.some(
                    (targetItem) =>
                      targetItem.id === payload.dataObj.id &&
                      targetItem.name === payload.dataObj.name,
                  )
                ) {
                  return {
                    ...target,
                    [targetDataKey]: target[targetDataKey]?.filter(
                      (item) => item.id !== payload.dataObj.id,
                    ),
                  };
                } else {
                  return {
                    ...target,
                    [targetDataKey]: [...(target[targetDataKey] ?? []), payload.dataObj], // but here target[targetDataKey] should never be null or undefined!
                  };
                }
              }

              return target;
            }),
          };
        }

        return targetCombination;
      });
    },
    removeTargetFromTC: (
      state: CtvTargetCombinationState[],
      {
        payload,
      }: PayloadAction<{
        targetCombinationId: string;
        targetId: string;
      }>,
    ) => {
      return state.map((targetCombination) => {
        if (targetCombination.id === payload.targetCombinationId) {
          return {
            ...targetCombination,
            targets: [
              ...targetCombination.targets.filter((target) => target.id !== payload.targetId),
            ],
          };
        }

        return targetCombination;
      });
    },
    onCheckAll: (
      state: CtvTargetCombinationState[],
      {
        payload,
      }: PayloadAction<{
        targetCombinationId: string;
        targetId: string;
        targetType: CtvTargetingTypes;
        dataObjs: CtvGenre[] | CtvApp[] | CtvPlatform[] | CtvIntentPersona[];
      }>,
    ) => {
      const targetDataKey = createKeyTypeForCtvTargetData(payload.targetType);

      return state.map((targetCombination) => {
        if (targetCombination.id === payload.targetCombinationId) {
          return {
            ...targetCombination,
            targets: targetCombination.targets.map((target) => {
              if (target.id === payload.targetId && target[targetDataKey] !== undefined) {
                if (target[targetDataKey]?.length === payload.dataObjs.length) {
                  return {
                    ...target,
                    [targetDataKey]: [],
                  };
                } else {
                  // else for understanding. My understanding!
                  return {
                    ...target,
                    [targetDataKey]: [...payload.dataObjs],
                  };
                }
              }

              return target;
            }),
          };
        }

        return targetCombination;
      });
    },
    onRemoveAll: (
      state: CtvTargetCombinationState[],
      {
        payload,
      }: PayloadAction<{
        targetId: string;
        targetCombinationId: string;
        targetType: CtvTargetingTypes;
      }>,
    ) => {
      const targetDataKey = createKeyTypeForCtvTargetData(payload.targetType);

      return state.map((targetCombination) => {
        if (targetCombination.id === payload.targetCombinationId) {
          return {
            ...targetCombination,
            targets: targetCombination.targets.map((target) => {
              if (target.id === payload.targetId && target[targetDataKey] !== undefined) {
                return {
                  ...target,
                  [targetDataKey]: [],
                };
              }

              return target;
            }),
          };
        }

        return targetCombination;
      });
    },
    onRemoveOne: (
      state: CtvTargetCombinationState[],
      {
        payload,
      }: PayloadAction<{
        targetId: string;
        targetCombinationId: string;
        targetType: CtvTargetingTypes;
        dataObj: CtvGenre | CtvPlatform | CtvApp | CtvIntentPersona;
      }>,
    ) => {
      const targetDataKey = createKeyTypeForCtvTargetData(payload.targetType);

      return state.map((targetCombination) => {
        if (targetCombination.id === payload.targetCombinationId) {
          return {
            ...targetCombination,
            targets: targetCombination.targets.map((target) => {
              if (target.id === payload.targetId && target[targetDataKey] !== undefined) {
                if (
                  target[targetDataKey]?.some(
                    (targetItem) =>
                      targetItem.id === payload.dataObj.id &&
                      targetItem.name === payload.dataObj.name,
                  )
                ) {
                  return {
                    ...target,
                    [targetDataKey]: target[targetDataKey]?.filter(
                      (item) => item.id !== payload.dataObj.id,
                    ),
                  };
                }
              }

              return target;
            }),
          };
        }

        return targetCombination;
      });
    },
    onExcludeChange: (
      state: CtvTargetCombinationState[],
      {
        payload,
      }: PayloadAction<{
        targetId: string;
        targetCombinationId: string;
        targetType: CtvTargetingTypes;
        exclude: boolean;
      }>,
    ) => {
      const targetDataKey = createKeyTypeForCtvTargetData(payload.targetType);

      return state.map((targetCombination) => {
        if (targetCombination.id === payload.targetCombinationId) {
          return {
            ...targetCombination,
            targets: targetCombination.targets.map((target) => {
              if (target.id === payload.targetId && target[targetDataKey] !== undefined) {
                return {
                  ...target,
                  exclude: payload.exclude,
                };
              }

              return target;
            }),
          };
        }

        return targetCombination;
      });
    },
    addTargetCombinationsFromApi: (
      state: CtvTargetCombinationState[],
      { payload }: PayloadAction<CtvTargetCombination[]>,
    ) => {
      return [...payload];
    },
    resetCtvTargetCombinationState: () => {
      return [...initialState];
    },
  },
});

export const {
  addOneTargetCombination,
  removeOneTargetCombination,
  addTargetTypeToTargetCombination,
  addTargetTypeDataToSpecificTargetInTC,
  removeTargetFromTC,
  onCheckAll,
  onRemoveAll,
  onRemoveOne,
  onExcludeChange,
  addTargetCombinationsFromApi,
  resetCtvTargetCombinationState,
} = ctvTargetCombinationsSlice.actions;
export const ctvTargetCombinationsReducer = ctvTargetCombinationsSlice.reducer;
