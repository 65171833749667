import { Progress } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { OrgCategoryNode } from '../../types';
import { PanelContentProps } from './PanelContent';

export const CategoryPanel = ({ node }: PanelContentProps) => {
  if (!node) {
    return null;
  }

  return (
    <>
      <section className="mx-auto flex flex-col items-start gap-1 pb-8">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">Category</h2>
        <p className="max-w-2xl text-base font-light">{node.name}</p>
      </section>

      <section className="flex flex-col gap-4 w-full pb-8">
        {(node as OrgCategoryNode).intentPrompts.map((sentence, index) => (
          <div
            key={index}
            className="flex flex-row gap-2 w-full border-b border-opacity-25 border-nano-pca-purple pb-4 last-of-type:border-0 last-of-type:pb-0"
          >
            <div className="text-base-l w-2/3">
              <p className=""> {sentence} </p>
            </div>
          </div>
        ))}
      </section>

      <section className="mx-auto flex flex-col items-start gap-1">
        <h2 className="text-primary-dark-purple-100 text-base-l font-bold">Urls</h2>

        <div className="flex flex-col gap-4 w-full">
          {(node as OrgCategoryNode).urls.map((urlObj, index) => (
            <div
              key={index}
              className="flex flex-row gap-2 w-full border-b border-opacity-25 border-nano-pca-purple pb-4"
            >
              <div className="text-base-l w-2/3">
                <Link
                  to={urlObj.url}
                  target="_blank"
                  className="text-base-s no-underline cursor-pointer break-words"
                >
                  {urlObj.url}
                </Link>
              </div>
              <div className="w-1/3 px-1">
                <Progress
                  percent={Math.round(urlObj.similarity * 100)}
                  className="w-full"
                  strokeColor="#7f5ea7"
                  trailColor="#f0f0f0"
                  strokeLinecap="round"
                  size="default"
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
