import { HierarchyNode } from 'd3-hierarchy';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'lucide-react';

import { OrgNodeData } from '../../types';

type NodeButtonProps = {
  node: HierarchyNode<OrgNodeData>;
  orientation: string;
};

export const NodeButton = ({ node, orientation }: NodeButtonProps) => {
  let ChevronOpen = ChevronRight;
  let ChevronClosed = ChevronLeft;
  let flex = 'flex-row';
  let widthOrHeight = 'w-[30px]';

  if (orientation !== 'left') {
    ChevronOpen = ChevronDown;
    ChevronClosed = ChevronUp;
    flex = 'flex-col';
    widthOrHeight = 'h-[30px] w-[60px]';
  }

  return (
    <>
      <div
        className={`${widthOrHeight} flex justify-center items-center border border-nano-pca-purple border-opacity-50 rounded-xl bg-secondary-white`}
      >
        <div
          className={`h-full w-full flex justify-center items-center ${flex} hover:bg-nano-pca-teal`}
        >
          <p className="text-base-s">{node.data.childrenCount}</p>
          <div>
            {node.children ? (
              <ChevronOpen className="w-[10px] h-[10px]" />
            ) : (
              <ChevronClosed className="w-[10px] h-[10px]" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
