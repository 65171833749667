import classNames from 'classnames';
import {
  // Blocks,
  ChartNoAxesCombined,
  FolderKanban,
  GraduationCap,
  House,
  LibraryBig,
  FileBox,
  TvMinimal,
} from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import LogoImg from 'assets/images/logo.svg';

import { NanoBot } from 'components/NanoBot';
import { UserProfilePopover } from 'components/UserProfile/UserProfilePopover';
import { IconButton, Tooltip } from 'components/common';

// import { segmentStorage } from 'modules/segment/segmentStorage';
import { NANO_TOUR_IDS } from 'modules/tour';

import { ROUTES } from 'routing/constants';

const NANO_INTERACTIVE_URL = 'https://www.nanointeractive.com/';
const SIDEBAR_BTN_CLASSES = classNames([
  'text-nano-pca-teal',
  'hover:bg-primary-electric-cyan-100/[.1]',
  'rounded-2xl',
]);

export const Sidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // const segmentBucketsCount = segmentStorage.getSegment()?.buckets?.length ?? 0;

  const NavigationList = useMemo(
    () => [
      {
        path: ROUTES.ROOT,
        Icon: House,
        tourId: NANO_TOUR_IDS.home,
        tooltipTitle: t('pages.home.tooltip'),
      },
      // {
      //   path: segmentBucketsCount > 0 ? ROUTES.NEW_SEGMENT_BUCKETS : ROUTES.NEW_SEGMENT,
      //   Icon: Blocks,
      //   tourId: NANO_TOUR_IDS.planning,
      //   tooltipTitle: t('pages.home.cardOne.title'),
      // },
      {
        path: ROUTES.MANAGEMENT,
        Icon: FolderKanban,
        tourId: NANO_TOUR_IDS.managementSidebar,
        tooltipTitle: t('pages.home.cardTwo.title'),
      },
      {
        path: ROUTES.CTV_LIST,
        Icon: TvMinimal,
        tourId: NANO_TOUR_IDS.ctvList,
        tooltipTitle: t('pages.home.ctv_list.title'),
      },
      // {
      //   path: ROUTES.ADVERTISER,
      //   Icon: Globe,
      //   tourId: NANO_TOUR_IDS.advertiser,
      //   tooltipTitle: t('pages.home.advertiser.title'),
      // },
      {
        path: ROUTES.MEASUREMENT,
        Icon: ChartNoAxesCombined,
        tourId: NANO_TOUR_IDS.reporting,
        tooltipTitle: t('pages.home.cardThree.title'),
      },
      {
        path: ROUTES.LIBRARY,
        Icon: LibraryBig,
        tourId: NANO_TOUR_IDS.library,
        tooltipTitle: t('pages.library.title'),
      },
      {
        path: ROUTES.UNIVERSITY,
        Icon: GraduationCap,
        tourId: NANO_TOUR_IDS.university,
        tooltipTitle: t('pages.university.hoverTitle'),
      },
      {
        path: ROUTES.TAG_WRAPPING_TOOL,
        Icon: FileBox,
        tourId: NANO_TOUR_IDS.university,
        tooltipTitle: t('pages.tagWrappingTool.hoverTitle'),
      },
    ],
    [t],
  );

  return (
    <aside className="sticky top-0 z-50 h-screen p-2 pr-2">
      <div className="flex h-full w-32 flex-col items-center overflow-y-auto rounded-2xl bg-icons-sidebar-gradient py-6 pb-7">
        <Link
          to={NANO_INTERACTIVE_URL}
          className="mt-[15px]"
          target="_blank"
          rel="noopener noreferrer"
          data-tour={NANO_TOUR_IDS.logo}
        >
          <img alt="Nano Interective" className="w-[72px]" src={LogoImg} />
        </Link>
        <nav className="flex flex-col my-auto gap-5">
          {NavigationList.map(({ path, Icon, tourId, tooltipTitle }) => (
            <Tooltip key={path} title={tooltipTitle} placement="right">
              <Link to={path} data-tour={tourId} tabIndex={-1}>
                <IconButton
                  className={classNames(
                    'group bg-primary-electric-cyan-100/[.1]',
                    SIDEBAR_BTN_CLASSES,
                    {
                      'bg-primary-electric-cyan-100/[.2] outline outline-2 text-nano-pca-teal':
                        pathname === path,
                    },
                  )}
                  size="large"
                >
                  <Icon
                    className={classNames(
                      'transition-transform duration-200 group-hover:scale-125',
                      {
                        'scale-125': pathname === path,
                      },
                    )}
                  />
                </IconButton>
              </Link>
            </Tooltip>
          ))}
        </nav>
        <div className="flex flex-col pt-6">
          <NanoBot />
          <UserProfilePopover />
        </div>
      </div>
    </aside>
  );
};
