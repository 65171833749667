import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { CtvApp, CtvGenre, CtvIntentPersona, CtvPlatform, CtvTargetState } from '../types';

const initialState: CtvTargetState = {
  id: uuid(),
  type: '',
  apps: [],
  intentPersonas: [],
  exclude: false,
  genres: [],
  platforms: [],
};

export const ctvTargetSlice = createSlice({
  name: 'CTV_TARGET_SLICE',
  initialState,
  reducers: {
    addGenre: (state: CtvTargetState, { payload }: PayloadAction<CtvGenre[]>) => {
      state.genres = [...payload];
    },
    addPlatform: (state: CtvTargetState, { payload }: PayloadAction<CtvPlatform[]>) => {
      state.platforms = [...payload];
    },
    addIntentPersona: (state: CtvTargetState, { payload }: PayloadAction<CtvIntentPersona[]>) => {
      state.intentPersonas = [...payload];
    },
    addApp: (state: CtvTargetState, { payload }: PayloadAction<CtvApp[]>) => {
      state.apps = [...payload];
    },
  },
});
export const { addGenre, addPlatform, addIntentPersona, addApp } = ctvTargetSlice.actions;

export const ctvTargetReducer = ctvTargetSlice.reducer;
