import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Checkbox, EntityTag, Text } from 'components/common';

import { setEntity } from 'modules/segment/segmentSlice';

interface EntityTagsListProps {
  tagsItems: { keyword: string; sentiment_max: number; sentiment_min: number }[];
  isIncluded: boolean;
  onClear: (tag: string) => void;
  onClearAll?: () => void;
  onSentimentChange: (keyword: string, sentiment_max: number, sentiment_min: number) => void;
}

export const EntityTagsList = ({
  tagsItems,
  isIncluded,
  onClear,
  onClearAll,
  onSentimentChange,
}: EntityTagsListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSentimentChange = useCallback(
    (keyword: string, sentiment_max: number, sentiment_min: number) => {
      const updatedTags = tagsItems.map((tag) =>
        tag.keyword === keyword ? { ...tag, sentiment_max, sentiment_min } : tag,
      );

      dispatch(setEntity(updatedTags));
      onSentimentChange(keyword, sentiment_max, sentiment_min);
    },
    [dispatch, onSentimentChange, tagsItems],
  );

  return (
    <div className="relative flex flex-col rounded-lg bg-secondary-mint px-5 pb-6 pt-4">
      <div className="flex justify-between">
        <Text className="mb-3 text-base-l font-medium">{t('segment.tagList.yourSelection')}</Text>
        {Boolean(tagsItems.length) && (
          <Button
            size="small"
            variant="text"
            onClick={onClearAll}
            className="cursor-pointer text-base-s font-semibold text-secondary-purple"
          >
            {t('common.clearAll')}
          </Button>
        )}
      </div>
      <div className="gap-6 flex flex-col max-h-[475px] overflow-y-auto">
        {tagsItems.length ? (
          tagsItems.map((tagItem, index) => (
            <TagsListItem
              key={`${tagItem}${index}`}
              text={tagItem.keyword}
              isIncluded={isIncluded}
              onClose={onClear}
              sentiment_max={tagItem.sentiment_max}
              sentiment_min={tagItem.sentiment_min}
              onSentimentChange={handleSentimentChange}
            />
          ))
        ) : (
          <Text className="text-base-l text-text-60">{t('segment.tagList.empty')}</Text>
        )}
      </div>

      <div className="absolute bottom-0 right-0 h-[37px] rounded-lg bg-text-80/[0.12] px-3 leading-10">
        {tagsItems.length}
      </div>
    </div>
  );
};

interface EntityTagsListItemProps {
  text: string;
  isIncluded: boolean;
  onClose: (item: string) => void;
  sentiment_max: number;
  sentiment_min: number;
  onSentimentChange: (keyword: string, sentiment_max: number, sentiment_min: number) => void;
}

const TagsListItem = ({
  text,
  isIncluded,
  onClose,
  sentiment_max,
  sentiment_min,
  onSentimentChange,
}: EntityTagsListItemProps) => {
  const handleClose = useCallback(() => {
    onClose(text);
  }, [onClose, text]);

  const handlePositiveChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const isPositiveChecked = e.target.checked;
      const newSentimentMax = isPositiveChecked ? 1 : 0;

      if (!isPositiveChecked && sentiment_min === 0) {
        return;
      }

      onSentimentChange(text, newSentimentMax, sentiment_min);
    },
    [onSentimentChange, text, sentiment_min],
  );

  const handleNegativeChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const isNegativeChecked = e.target.checked;
      const newSentimentMin = isNegativeChecked ? -1 : 0;

      if (!isNegativeChecked && sentiment_max === 0) {
        return;
      }
      onSentimentChange(text, sentiment_max, newSentimentMin);
    },
    [onSentimentChange, text, sentiment_max],
  );

  return (
    <div className="flex flex-row gap-6 h-[45px] w-full items-center">
      <EntityTag
        variant={isIncluded ? 'green' : 'red'}
        onClose={handleClose}
        className="h-[45px] w-[96%] flex items-center justify-between px-4"
      >
        <div className="flex-1 whitespace-normal">{text}</div>
        {/* Text takes available space */}
        <div className="flex flex-row gap-2">
          <Checkbox checked={sentiment_max === 1} onChange={handlePositiveChange}>
            <div className="text-xs">Positive</div>
          </Checkbox>
          <Checkbox checked={sentiment_min === -1} onChange={handleNegativeChange}>
            <div>Negative</div>
          </Checkbox>
        </div>
      </EntityTag>
    </div>
  );
};
