import { ColumnsType } from 'antd/es/table';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Table, Text } from 'components/common';
import { SortIcon } from 'components/common/icons';

import { ROUTES } from 'routing/constants';

import { usePreboundCallback } from 'hooks/usePreboundCallback';

import { useCompanyListQuery } from '../../company';
import { UserListItem } from '../userApiTypes';
import { getIsUserLoading, getUserToDeleteId } from '../userSelectors';
import { setUserToDeleteId } from '../usersSlice';
import { NoData } from './NoData';
import { UserListActions } from './UserListActions';
import { useUserDelete } from './useUserDelete';
import { useTeamsListQuery } from './usersListApiSlice';

type UsersTableProps = {
  dataSource: UserListItem[];
};

export const UsersTable = ({ dataSource }: UsersTableProps) => {
  const navigate = useNavigate();
  const navPath = ROUTES.USER_BY_ID;
  const { data: companies, isLoading: loadingCompanies } = useCompanyListQuery(null);
  const { data: teams, isLoading: loadingTeams } = useTeamsListQuery(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(getIsUserLoading);
  const userToDeleteId = useSelector(getUserToDeleteId);
  const confirmDelete = useUserDelete();

  const findCompanyNameById = useMemo(
    () =>
      (companyId: string): string | null => {
        const company = companies?.find((c) => c.id === companyId);

        return company ? company.name : 'N/A';
      },
    [companies],
  );

  const findTeamNameById = useMemo(
    () =>
      (teamId: string | null): string => {
        const team = teams?.find((c) => c.id === teamId);

        return team ? team.name : 'N/A';
      },
    [teams],
  );

  const handleDelete = useCallback(
    (id: string) => {
      dispatch(setUserToDeleteId(id));
      confirmDelete();
    },
    [confirmDelete, dispatch],
  );

  const handleNavigateToEdit = usePreboundCallback((id: string) => {
    navigate(navPath.replace(':id', id), { relative: 'path' });
  });

  const columns: ColumnsType<UserListItem> = useMemo(
    () => [
      {
        title: t('adminPanel.user.table.name'),
        dataIndex: 'name',
        render: (text, { name, surname, id }) => {
          const displayName = name ? `${name} ${surname}` : 'N/A';

          return (
            <Link
              className="segment-name-link text-base-l font-bold no-underline"
              to={ROUTES.USER_BY_ID.replace(':id', id)}
            >
              {displayName}
            </Link>
          );
        },
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortIcon: () => <SortIcon />,
        showSorterTooltip: false,
        ellipsis: true,
        width: '30%',
      },
      {
        title: t('adminPanel.user.table.email'),
        dataIndex: 'email',
        render: (email) => <Text large>{email}</Text>,
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortIcon: () => <SortIcon />,
        showSorterTooltip: false,
        ellipsis: true,
        width: '25%',
      },
      {
        title: t('adminPanel.user.table.company'),
        dataIndex: 'companyId',
        render: (companyId) => <Text large>{findCompanyNameById(companyId)}</Text>,
        sorter: (a, b) =>
          findCompanyNameById(a.companyId)!.localeCompare(findCompanyNameById(b.companyId)!),
        sortIcon: () => <SortIcon />,
        showSorterTooltip: false,
        ellipsis: true,
        width: '20%',
      },
      {
        title: t('adminPanel.user.table.team'),
        dataIndex: 'teamId',
        render: (teamId) => <Text large>{findTeamNameById(teamId)}</Text>,
        sorter: (a, b) => findTeamNameById(a.teamId)!.localeCompare(findTeamNameById(b.teamId)!),
        sortIcon: () => <SortIcon />,
        showSorterTooltip: false,
        ellipsis: true,
        width: '20%',
      },
      {
        width: '5%',

        render: ({ id }) => {
          const deleteUser = () => {
            handleDelete(id);
          };

          const displayLoader = id === userToDeleteId;
          const getUser = handleNavigateToEdit(id);

          return (
            <UserListActions
              onUserEdit={getUser}
              onUserDelete={() => deleteUser()}
              isLoading={isLoading}
              displayLoader={displayLoader}
            />
          );
        },
      },
    ],
    [
      findCompanyNameById,
      findTeamNameById,
      handleDelete,
      handleNavigateToEdit,
      isLoading,
      t,
      userToDeleteId,
    ],
  );

  return (
    <>
      <Table
        className="nano-keyword-groups-table"
        columns={columns}
        dataSource={dataSource}
        locale={{ emptyText: <NoData /> }}
        rowKey="id"
        loading={isLoading || loadingCompanies || loadingTeams}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showTotal: (total, [min, max]) => (
            <>
              {min} - {max} of {total}
            </>
          ),
          locale: { items_per_page: '' },
        }}
      />
    </>
  );
};
