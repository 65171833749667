import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { companyListApiSlice } from '../company';
import { auditLogApi } from './auditLogApi';
import { getAudit } from './auditLogSelectors';
import {
  setAuditLogSearch,
  setAuditLogSearchFailure,
  setAuditLogSearchSuccess,
  setAuditLogSingleLine,
  setAuditLogSingleLineFailure,
  setAuditLogSingleLineSuccess,
} from './auditLogSlice';
import {
  AuditLogResponseItem,
  AuditLogSingleLineParams,
  AuditLogSingleLineResponse,
  AuditLogState,
  SearchAuditLogParams,
} from './types';

function* auditLogSearch() {
  try {
    const auditLog: AuditLogState = yield select(getAudit);

    const fromObj = new Date(auditLog.startDate);
    const toObj = new Date(auditLog.endDate);
    const fromString = fromObj.toLocaleDateString('en-GB').replace(/\//g, '-');
    const toString = toObj.toLocaleDateString('en-GB').replace(/\//g, '-');

    const auditLogData: SearchAuditLogParams = {
      object_type: auditLog.objectType,
      id: auditLog.id,
      start_date: fromString,
      end_date: toString,
      user_id: auditLog.userId,
    };

    (Object.keys(auditLogData) as (keyof SearchAuditLogParams)[]).forEach((key) => {
      if (auditLogData[key] === '' || auditLogData[key] === null) {
        delete auditLogData[key];
      }
    });

    const response: AuditLogResponseItem[] = yield call(auditLogApi.getAuditLog, auditLogData);

    yield put(setAuditLogSearchSuccess(response));
  } catch (e) {
    notifyError(e);

    yield put(
      setAuditLogSearchFailure({
        message: i18next.t('adminPanel.auditLog.errors.auditLogSearchFailed'),
      }),
    );
  }
}

function* getAuditLogSingleLine({ payload }: PayloadAction<AuditLogSingleLineParams>) {
  try {
    const params: AuditLogSingleLineParams = {
      datetime: payload.datetime,
      entity_id: payload.entity_id,
      user_id: payload.user_id,
    };
    const response: AuditLogSingleLineResponse = yield call(
      auditLogApi.getAuditLogSingleLine,
      params,
    );

    yield put(setAuditLogSingleLineSuccess(response));
    yield put(companyListApiSlice.util.invalidateTags(['AuditLog']));
  } catch (e) {
    notifyError(e);

    yield put(
      setAuditLogSingleLineFailure({
        message: i18next.t('adminPanel.auditLog.errors.auditLogSingleLineFailed'),
      }),
    );
  }
}

export function* auditLogSagas() {
  yield all([
    takeLatest(setAuditLogSearch.type, auditLogSearch),
    takeLatest(setAuditLogSingleLine.type, getAuditLogSingleLine),
  ]);
}
