export const NANO_TOUR_IDS = {
  logo: 'nano_logo',
  home: 'nano_tour_home',
  planning: 'nano_tour_planning',
  managementSidebar: 'nano_tour_management_sidebar',
  advertiser: 'nano_tour_advertiser',
  library: 'nano_tour_library',
  university: 'nano_tour_university',
  reporting: 'nano_tour_reporting',
  bot: 'nano_tour_bot',
  profile: 'nano_tour_profile',
  planningCard: 'nano_tour_planning_card',
  managementCard: 'nano_tour_management_card',
  reportingCard: 'nano_tour_reporting_card',
  tagWrappingTool: 'nano_tag_wrapping_tool',
  ctvList: 'nano_ctv_list',
};
