import { AdminPanelPage } from 'pages/adminPanel/AdminPanelPage';
import { UserListPage } from 'pages/adminPanel/userPages/UserListPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { NanoTourWrapper } from 'modules/tour';

import { DialogManager } from '../components/dialog/DialogManager';
import { IntentEstimateOrg } from '../modules/intentEstimateFlowDiagram/IntentEstimateOrg';
import { IntentEstimateFlowDiagramPage } from '../pages/IntentEstimateFlowDiagramPage';
import { DistanceCheckerPage } from '../pages/adminPanel/distanceChecker/DistanceCheckerPage';
import { AdminPanelAccessRoute } from './AdminPanelAccessRoute';
import { ProtectedLayout } from './ProtectedLayout';
import { ROUTES } from './constants';
import {
  // AutomatedStepPage,
  HomePage,
  LibraryPage,
  ManualStepPage,
  MeasurementPage,
  NotFoundPage,
  SegmentBucketsPage,
  SegmentDetailsPage,
  SegmentInfoPage,
  SegmentListPage,
  CustomCategoriesListPage,
  CustomCategoryPage, // SegmentModeSelectionPage,
  SegmentSavePage,
  TargetingOption,
  UniversityPage,
  WelcomePage,
  KeywordGroupFormPage,
  KeywordGroupListPage,
  CompanyListPage,
  TeamListPage,
  UserPage,
  ExclusionListPage,
  AuditLogPage,
  SearchByVectorPage,
  ContextaPage,
  SegmentGroupListPage,
  DomainListPage,
  TagWrappingToolPage,
  AdvertiserListPage,
  PixelListPage,
  CtvPage,
  CtvListPage,
} from './lazyPageImports';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <NanoTourWrapper>
        <DialogManager />
        <Routes>
          <Route
            index
            element={
              <ProtectedLayout>
                <HomePage />
              </ProtectedLayout>
            }
          />
          {/*<Route*/}
          {/*  path={ROUTES.NEW_SEGMENT}*/}
          {/*  element={*/}
          {/*    <ProtectedLayout contentClassName="flex flex-col items-center justify-center">*/}
          {/*      <SegmentModeSelectionPage />*/}
          {/*    </ProtectedLayout>*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path={ROUTES.NEW_SEGMENT_AUTOMATED}*/}
          {/*  element={*/}
          {/*    <ProtectedLayout>*/}
          {/*      <AutomatedStepPage />*/}
          {/*    </ProtectedLayout>*/}
          {/*  }*/}
          {/*/>*/}
          <Route
            path={ROUTES.NEW_SEGMENT_MANUAL}
            element={
              <ProtectedLayout>
                <ManualStepPage />
              </ProtectedLayout>
            }
          >
            <Route path=":targetingOption" element={<TargetingOption />} />
          </Route>
          <Route
            path={ROUTES.NEW_SEGMENT_BUCKETS}
            element={
              <ProtectedLayout>
                <SegmentBucketsPage nextPath="../../general-info" />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.NEW_SEGMENT_GENERAL_INFO}
            element={
              <ProtectedLayout>
                <SegmentInfoPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.SEGMENT_BY_ID}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 pl-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <SegmentDetailsPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.SEGMENT_BY_ID_BUCKETS}
            element={
              <ProtectedLayout>
                <SegmentBucketsPage nextPath="../.." />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.SEGMENT_BY_ID_MANUAL}
            element={
              <ProtectedLayout>
                <ManualStepPage />
              </ProtectedLayout>
            }
          >
            <Route path=":targetingOption" element={<TargetingOption />} />
          </Route>
          <Route
            path={ROUTES.SEGMENT_BY_ID_SAVE}
            element={
              <ProtectedLayout>
                <SegmentSavePage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.MANAGEMENT}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <SegmentListPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.ADVERTISER}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdvertiserListPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.PIXEL}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <PixelListPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.MEASUREMENT}
            element={
              <ProtectedLayout>
                <MeasurementPage />
              </ProtectedLayout>
            }
          />
          <Route path={ROUTES.WELCOME} element={<WelcomePage />} />
          <Route
            path={ROUTES.UNIVERSITY}
            element={
              <ProtectedLayout>
                <UniversityPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.LIBRARY}
            element={
              <ProtectedLayout>
                <LibraryPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.CUSTOM_CATEGORY_LIST}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <CustomCategoriesListPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.NEW_CUSTOM_CATEGORY}
            element={
              <ProtectedLayout>
                <AdminPanelAccessRoute>
                  <CustomCategoryPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.CUSTOM_CATEGORY_BY_ID}
            element={
              <ProtectedLayout>
                <AdminPanelAccessRoute>
                  <CustomCategoryPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.ADMIN_PANEL}
            element={
              <ProtectedLayout>
                <AdminPanelAccessRoute>
                  <AdminPanelPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.USERS}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <UserListPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.USER}
            element={
              <ProtectedLayout>
                <AdminPanelAccessRoute>
                  <UserPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.USER_BY_ID}
            element={
              <ProtectedLayout>
                <AdminPanelAccessRoute>
                  <UserPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.COMPANY}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <CompanyListPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.KEYWORD_GROUP}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <KeywordGroupListPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          ></Route>
          <Route
            path={ROUTES.KEYWORD_GROUP_BY_ID}
            element={
              <ProtectedLayout>
                <AdminPanelAccessRoute>
                  <KeywordGroupFormPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          ></Route>
          <Route
            path={ROUTES.NEW_KEYWORD_GROUP}
            element={
              <ProtectedLayout>
                <AdminPanelAccessRoute>
                  <KeywordGroupFormPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          ></Route>
          <Route
            path={ROUTES.TEAM}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <TeamListPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.SEARCH_BY_VECTOR}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <SearchByVectorPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.CONTEXTA}
            element={
              <ProtectedLayout>
                <AdminPanelAccessRoute>
                  <ContextaPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.EXCLUSION_LIST}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <ExclusionListPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.AUDIT}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <AuditLogPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.SEGMENT_GROUP}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <SegmentGroupListPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.DISTANCE_CHECKER}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <DistanceCheckerPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.DOMAIN_LIST}
            element={
              <ProtectedLayout
                zeroContentPadding
                contentClassName="p-10 pb-0 flex flex-col"
                className="bg-secondary bg-cover"
              >
                <AdminPanelAccessRoute>
                  <DomainListPage />
                </AdminPanelAccessRoute>
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.TAG_WRAPPING_TOOL}
            element={
              <ProtectedLayout>
                <TagWrappingToolPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.INTENT_ESTIMATE_FLOW_DIAGRAM}
            element={
              <ProtectedLayout zeroContentPadding>
                <IntentEstimateFlowDiagramPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.INTENT_ESTIMATE_ORG}
            element={
              <ProtectedLayout zeroContentPadding>
                <IntentEstimateOrg />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.NEW_CTV}
            element={
              <ProtectedLayout>
                <CtvPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.CTV_LIST}
            element={
              <ProtectedLayout zeroContentPadding contentClassName="p-10 pb-0 flex flex-col">
                <CtvListPage />
              </ProtectedLayout>
            }
          />
          <Route
            path={ROUTES.CTV_BY_ID}
            element={
              <ProtectedLayout>
                <CtvPage />
              </ProtectedLayout>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </NanoTourWrapper>
    </BrowserRouter>
  );
};
