import React from 'react';

import { OrgIntentGroupNode } from '../../types';

export const IntentGroupNodeContent = ({ node }: { node: OrgIntentGroupNode }) => {
  const active: boolean = node._highlighted || node._upToTheRootHighlighted;

  // Node content text will be intent prompt if exists (in most cases it will)
  const nodeContentText =
    node.intentGroup.keywords.length > 0 ? node.intentGroup.keywords[0] : node.name;

  return (
    <div
      className={`rounded-2xl  ${
        active ? 'border-4 border-secondary-darkPurple p-3' : 'border border-nano-light-purple p-4'
      } box-border bg-uploaded-file-hover-gradient w-full h-full overflow-y-scroll`}
    >
      <section className="text-base-s whitespace-normal break-words w-full max-w-full max-h-full text-secondary-darkPurple overflow-y-scroll">
        {nodeContentText}
      </section>
    </div>
  );
};
