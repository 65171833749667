import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notificationService } from '../notifications';
import { ctvSegmentApi } from './ctvApi';
import {
  deleteCtvSegment,
  deleteCtvSegmentFailure,
  deleteCtvSegmentSuccess,
  getCtvSegmentById,
  getCtvSegmentByIdFailure,
  getCtvSegmentByIdSuccess,
  saveCtvSegment,
  saveCtvSegmentFailed,
  saveCtvSegmentSuccess,
  updateCtvSegment,
  updateCtvSegmentFailure,
  updateCtvSegmentSuccess,
} from './ctvSegmentSlice';
import { getCtvSegmentState } from './ctvSelectors';
import { ctvSegmentListApiSlice } from './list/ctvSegmentListApiSlice';
import { addTargetCombinationsFromApi } from './targeting/ctvTargetCombinationsSlice';
import { CtvSegmentRequest, CtvSegmentResponse, CtvSegmentState } from './types';

function* saveCtvSegmentSaga() {
  try {
    const dataFromState: CtvSegmentState = yield select(getCtvSegmentState);

    const dataForRequest: CtvSegmentRequest = {
      id: dataFromState.id,
      name: dataFromState.name,
      ssp: dataFromState.partner,
      expiredDate: dataFromState.expiredDate,
      targetCombinations: dataFromState.targetCombinationsForApi,
      dataOnly: dataFromState.dataOnly,
      group: dataFromState.group,
      publicSegment: dataFromState.publicSegment,
      hideNameForPartner: dataFromState.hideNameForPartner,
      parentId: dataFromState.parentId,
    };

    const response: CtvSegmentResponse = yield call(ctvSegmentApi.saveCtvSegment, dataForRequest);

    yield put(saveCtvSegmentSuccess(response));

    notificationService.showSuccess({
      key: 'saveCtvSegmentSuccess',
      message: i18next.t('ctv.notifications.saveSuccessful'),
    });

    yield put(ctvSegmentListApiSlice.util.invalidateTags(['Ctv']));
  } catch (e) {
    yield put(saveCtvSegmentFailed(i18next.t('ctv.errors.saveFailed')));
  }
}

function* deleteCtvSegmentSaga() {
  try {
    const { ctvSegmentToDeleteId } = yield select(getCtvSegmentState);

    yield call(ctvSegmentApi.deleteCtvSegment, ctvSegmentToDeleteId);

    yield put(deleteCtvSegmentSuccess());

    notificationService.showSuccess({
      key: 'deleteCtvSegmentSuccess',
      message: i18next.t('ctv.notifications.deleteSuccessful'),
    });

    yield put(ctvSegmentListApiSlice.util.invalidateTags(['Ctv']));
  } catch (e) {
    yield put(deleteCtvSegmentFailure(i18next.t('ctv.errors.deleteFailed')));
  }
}

function* updateCtvSegmentSaga() {
  try {
    const dataFromState: CtvSegmentState = yield select(getCtvSegmentState);

    if (!dataFromState.id) {
      return;
    }

    const dataForRequest: CtvSegmentRequest = {
      id: dataFromState.id,
      name: dataFromState.name,
      ssp: dataFromState.partner,
      expiredDate: dataFromState.expiredDate,
      targetCombinations: dataFromState.targetCombinationsForApi,
      dataOnly: dataFromState.dataOnly,
      group: dataFromState.group,
      publicSegment: dataFromState.publicSegment,
      hideNameForPartner: dataFromState.hideNameForPartner,
      parentId: dataFromState.parentId,
    };

    const response: CtvSegmentResponse = yield call(
      ctvSegmentApi.updateCtvSegment,
      dataForRequest.id,
      dataForRequest,
    );

    yield put(updateCtvSegmentSuccess(response));

    notificationService.showSuccess({
      key: 'updateCtvSegmentSuccess',
      message: i18next.t('ctv.notifications.updateSuccessful'),
    });

    yield put(ctvSegmentListApiSlice.util.invalidateTags(['Ctv']));
  } catch (e) {
    yield put(updateCtvSegmentFailure(i18next.t('ctv.errors.updateFailed')));
  }
}

function* getCtvSegmentByIdSaga() {
  try {
    const { id } = yield select(getCtvSegmentState);

    const response: CtvSegmentResponse = yield call(ctvSegmentApi.getCtvSegmentById, id);

    yield put(getCtvSegmentByIdSuccess(response));

    yield put(addTargetCombinationsFromApi(response.targetCombinations));
  } catch (e) {
    yield put(getCtvSegmentByIdFailure(i18next.t('ctv.errors.getFailed')));
  }
}

export function* ctvSegmentSagas() {
  yield all([
    takeLatest(saveCtvSegment.type, saveCtvSegmentSaga),
    takeLatest(deleteCtvSegment.type, deleteCtvSegmentSaga),
    takeLatest(updateCtvSegment.type, updateCtvSegmentSaga),
    takeLatest(getCtvSegmentById.type, getCtvSegmentByIdSaga),
  ]);
}
