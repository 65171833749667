import { nanoApi } from 'redux/rootApiSlice';

import { CTV_SEGMENT_LIST_URL } from '../constants';
import { CtvListItem } from '../types';

export const ctvSegmentListApiSlice = nanoApi.injectEndpoints({
  endpoints: (builder) => ({
    ctvSegmentList: builder.query<CtvListItem[], null>({
      query: () => ({ method: 'GET', url: `${CTV_SEGMENT_LIST_URL}` }),
      providesTags: ['Ctv'],
    }),
  }),
});

export const { useCtvSegmentListQuery } = ctvSegmentListApiSlice;
