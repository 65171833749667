export const CTV_GENRES_URL = '/app-genre';
export const CTV_PLATFORM_URL = '/app-platforms';
export const CTV_INTENT_PERSONA_URL = '/app-personas';
export const CTV_APP_URL = '/app-data';

export const CTV_TARGETING_TYPE_GENRE = 'genre';
export const CTV_TARGETING_TYPE_APP = 'app';
export const CTV_TARGETING_TYPE_INTENT_PERSONA = 'intentPersona';
export const CTV_TARGETING_TYPE_PLATFORM = 'platform';
export type CtvTargetingTypes =
  | typeof CTV_TARGETING_TYPE_GENRE
  | typeof CTV_TARGETING_TYPE_APP
  | typeof CTV_TARGETING_TYPE_INTENT_PERSONA
  | typeof CTV_TARGETING_TYPE_PLATFORM;

export type CtvGenre = {
  id: string;
  name: string;
};

export type CtvGenreTarget = {
  exclude: boolean;
  genres: CtvGenre[];
};

export type CtvGenreResponse = CtvGenre;

export type CtvSegment = {
  id: string;
  name: string;
  partnerSeatId: string;
};

export type CtvIntentPersona = {
  id: string;
  name: string;
};

export type CtvIntentPersonaTarget = {
  exclude: boolean;
  intentPersonas: CtvIntentPersona[];
};

export type CtvIntentPersonaResponse = CtvIntentPersona;

export type CtvSegmentResponseGroup = {
  ssp: string;
  groupName: string;
  sspGroupId: string;
  price: number;
  id: string;
};

export type CtvSegmentResponse = {
  id: string;
  name: string;
  expiredDate: string;
  partner: string;
  group: CtvSegmentResponseGroup;
  publicSegment: boolean;
  parentId: string | null;
  hideNameForPartner: boolean;
  targetCombinations: CtvTargetCombination[];
};

export type CtvSegmentState = {
  id: string;
  name: string;
  partner: string;
  seatId: string;
  expiredDate: string;
  group: string | null;
  publicSegment: boolean;
  parentId: string | null;
  hideNameForPartner: boolean;
  ctvSegmentToDeleteId: string;
  targetCombinations: CtvTargetCombination[];
  targetCombinationsForApi: CtvTargetCombinationForApi[];
  dataOnly: boolean;

  isLoading: boolean;
  isCreated: boolean;
  failMassage: string;
};

export type CtvPlatform = {
  id: string;
  name: string;
};

export type CtvPlatformTarget = {
  exclude: boolean;
  platforms: CtvPlatform[];
};

export type CtvPlatformResponse = CtvPlatform;

export type CtvApp = {
  id: string;
  name: string;
};

export type CtvAppTarget = {
  exclude: boolean;
  app: CtvApp[];
};

export type CtvAppResponse = CtvApp;

export type CtvTargetingObj = {
  exclude: boolean;
  type: string;
  platforms?: CtvPlatform[];
  genres?: CtvGenre[];
  apps?: CtvApp[];
  intentPersonas?: CtvIntentPersona[];
};

export type CtvTarget = CtvTargetingObj & { id: string };

export type CtvTargetState = CtvTargetingObj & { id: string }; // Only one target state

export type CtvTargetCombination = {
  id: string;
  targets: CtvTargetState[];
};

export type CtvTargetCombinationState = CtvTargetCombination;

export type CtvListItem = {
  id: string;
  name: string;
  ssp: string;
  dataOnly: boolean;
};

export type CtvTargetForApi = {
  id: string;
  exclude: boolean;
  type: string;
  platforms?: string[] | undefined;
  genres?: string[] | undefined;
  apps?: string[] | undefined;
  intentPersonas?: string[] | undefined;
};
export type CtvTargetCombinationForApi = {
  id: string;
  targets: CtvTargetForApi[];
};

export type CtvSegmentSave = {
  name: string;
  partner: string;
  expiredDate: string;
  targetCombinationsForApi: CtvTargetCombinationForApi[];
  dataOnly: boolean;
  group: string | null;
  publicSegment: boolean;
  hideNameForPartner: boolean;
  parentId: string | null;
};

export type CtvSegmentUpdate = CtvSegmentSave & { id: string };

export type CtvSegmentRequest = {
  id: string;
  name: string;
  ssp: string;
  expiredDate: string;
  targetCombinations: CtvTargetCombinationForApi[];
  dataOnly: boolean;
  group: string | null;
  publicSegment: boolean;
  hideNameForPartner: boolean;
  parentId: string | null;
};

export type CtvSegmentFormData = {
  name: string;
  partner: string;
  seatId: string;
  expiredDate: string;
  dataOnly: boolean;
  group: string | null;
  publicSegment: boolean;
  hideNameForPartner: boolean;
  parentId: string | null;
};
