export const LOGOUT_URL = 'auth/logout/liift';
export const PASSWORD_RESET_URL = 'auth/password-reset';
export const REQUEST_PASSWORD_RESET_URL = 'auth/request-password-reset';
export const SSO_URL = 'auth/sso/url';
export const SSO_LOGOUT = 'auth/sso/logout';
export const REGENERATE_TOKEN_URL = 'auth/regenerate-token';
export const SSO_EXCHANGE = 'auth/sso/exchange';
export const AZURE_PROVIDER_OAUTH_URL = 'oauth2/azure/liift/url';
export const AZURE_PROVIDER_OAUTH_EXCHANGE_URL = 'oauth2/azure/liift/exchange';
export const USER_PROFILE = '/user/profile';
export const CURRENT_ROLE = 'roles/current';
export const TABLEAU_TOKEN = '/auth/tableau-token-report';
