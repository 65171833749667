import { ArrowLeft } from 'feather-icons-react';
import { useNavigate } from 'react-router';

import { Button } from '../common';

interface AdminPanelButtonProps {
  onClick?: () => void;
}

enum Routes {
  AdminPanel = '/admin-panel',
}

export const AdminPanelBackIcon = ({ onClick }: AdminPanelButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(Routes.AdminPanel);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button variant="text" size="small" className="pl-0" onClick={handleClick}>
      <ArrowLeft className="cursor-pointer" size={30} />
    </Button>
  );
};
