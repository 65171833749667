import { useTranslation } from 'react-i18next';

import { AdminPanelBackIcon } from 'components/AdminPanel/AdminPanelBackIcon';

import { UsersList } from 'modules/adminPanel/users/list/UsersList';

export const UserListPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <header>
        <section className="mx-auto flex flex-col items-start gap-1 px-4 pb-8">
          <div className="flex items-center gap-3">
            <AdminPanelBackIcon />
            <h2 className="text-primary-dark-purple-100 text-heading-l font-bold">
              {t('adminPanel.user.userListHeading')}
            </h2>
          </div>
          <p className="max-w-2xl text-base-l font-light">
            {t('adminPanel.home.user.description')}
          </p>
        </section>
      </header>
      <div>
        <UsersList />
      </div>
    </>
  );
};
