import React from 'react';

import { OrgDomainRootNode } from '../../types';

export const DomainRootNodeContent = ({ node }: { node: OrgDomainRootNode }) => {
  const active: boolean = node._highlighted || node._upToTheRootHighlighted;

  return (
    <div className="flex flex-col justify-center items-center h-full w-full rounded-2xl">
      <div className="bg-secondary-white h-full w-full rounded-2xl ">
        <div
          className={`rounded-2xl p-4 bg-gradient-to-r bg-nano-pca-teal bg-opacity-50 w-full h-full flex flex-row justify-start items-center ${
            active ? 'border-4 border-secondary-darkPurple' : 'border border-nano-light-purple'
          }`}
        >
          <p className="text-base-xl text-primary-dark-purple-100 whitespace-normal break-words w-full flex items-center justify-center ">
            {node.name}
          </p>
        </div>
      </div>
    </div>
  );
};
