export const DefaultLanguagesIsoCodes = ['en', 'de', 'fr', 'es', 'ar'];

export const SEGMENTS_URL = 'segment/getAll';
export const SEGMENT_URL = 'segment';
export const SEGMENT_DAYS_EXPIRATION = 6;
export const BRIEF_UPLOAD_URL = 'brief-upload';
export const trackingTagsFieldNames = {
  standard: [
    'click',
    'general',
    'impression',
    'viewability',
    'vastClick',
    'attrImpression',
    'attrClick',
    'attrConversion',
  ],
  video: [
    'complete',
    'firstQuartile',
    'fullscreen',
    'midPoint',
    'mute',
    'pause',
    'resume',
    'start',
    'thirdQuartile',
    'unmute',
  ],
};
export const defaultTrackingTagsState = {
  standard: {
    click: '',
    general: '',
    impression: '',
    viewability: '',
    vastClick: '',
    attrImpression: '',
    attrClick: '',
    attrConversion: '',
  },
  video: {
    complete: '',
    firstQuartile: '',
    fullscreen: '',
    midPoint: '',
    mute: '',
    pause: '',
    resume: '',
    start: '',
    thirdQuartile: '',
    unmute: '',
  },
};
export const defaultSensitiveSubjects = {
  isIncluded: false,
  isTouched: false,
  sensitiveSubjectsValues: [],
};

export const defaultSegmentExpiredDate = '0001-01-01T00:00:00Z';
