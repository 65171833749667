import { Skeleton } from 'antd';
import { ChevronDown } from 'feather-icons-react';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { EntityTagsList } from 'components/TagsList';
import { Checkbox, Input, Paragraph, TextArea, Select } from 'components/common';

import { splitBySeparator } from 'modules/adminPanel/keywordGroup/utils';
import { useGetEntitiesSuggestQuery } from 'modules/apiData/apiDataApiSlice';
import { getBucketEntities } from 'modules/segment/segmentSelectors';
import { removeEntityTag, setEntity } from 'modules/segment/segmentSlice';

import { keywordEntityTypes } from '../../../constants';

// Custom hook for debouncing the input value
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const Entity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const entity = useSelector(getBucketEntities);
  const isIncludedRef = useRef(entity.isIncluded);
  const [positiveChecked, setPositiveChecked] = useState(true);
  const [negativeChecked, setNegativeChecked] = useState(true);
  const [entityType, setEntityType] = useState('GENERAL');

  const [selectedEntities, setSelectedEntities] = useState<
    {
      keyword: string;
      sentiment_max: number;
      sentiment_min: number;
    }[]
  >(
    entity.entity.map((item) => ({
      keyword: `${item.keyword} - ${item.entity_type}`,
      sentiment_max: item.sentiment_max === 0 ? 0 : 1,
      sentiment_min: item.sentiment_min === 0 ? 0 : -1,
    })),
  );

  const [entitiesText, setEntitiesText] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data: suggestedEntities, isLoading: isLoadingSuggestedEntities } =
    useGetEntitiesSuggestQuery(debouncedSearchQuery, {
      skip: debouncedSearchQuery.length < 3,
    });

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  useEffect(() => {
    dispatch(setEntity(selectedEntities));
  }, [selectedEntities, dispatch]);

  const handleCheckboxChange = useCallback((value: string) => {
    setSelectedEntities((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.keyword === value);

      return isSelected
        ? prevSelected.filter((item) => item.keyword !== value)
        : [...prevSelected, { keyword: value, sentiment_max: 1, sentiment_min: -1 }];
    });
  }, []);

  const handleSentimentChange = useCallback(
    (keyword: string, sentiment_max: number, sentiment_min: number) => {
      setSelectedEntities((prevSelected) =>
        prevSelected.map((item) =>
          item.keyword === keyword ? { ...item, sentiment_max, sentiment_min } : item,
        ),
      );
    },
    [],
  );

  const handleClear = useCallback(
    (tagItem: string) => {
      const updatedEntities = selectedEntities.filter((entity) => entity.keyword !== tagItem);

      if (!entity.isIncluded) {
        setEntitiesText('');
      }

      setSelectedEntities(updatedEntities);
      dispatch(removeEntityTag(tagItem));
    },
    [dispatch, entity.isIncluded, selectedEntities],
  );

  const handleClearAll = useCallback(() => {
    setSelectedEntities([]);
    dispatch(setEntity([]));
    setEntitiesText('');
  }, [dispatch]);

  useEffect(() => {
    if (isIncludedRef.current !== entity.isIncluded) {
      setSelectedEntities([]);
      dispatch(setEntity([]));
      setEntitiesText('');
      setSearchQuery('');
      isIncludedRef.current = entity.isIncluded;
    }
  }, [entity.isIncluded, dispatch]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && event.shiftKey) {
      return;
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      const inputText = event.currentTarget.value;
      const inputValue = [
        ...new Set(
          splitBySeparator(inputText, '\n')
            .filter(Boolean)
            .map((item) => {
              return item.includes(' - ') ? item : `${item} - ${entityType}`;
            }),
        ),
      ];

      const newEntities = inputValue.map((keyword) => ({
        keyword,

        sentiment_max: positiveChecked ? 1 : 0,

        sentiment_min: negativeChecked ? -1 : 0,
      }));

      const updatedEntities = [...selectedEntities, ...newEntities];

      setSelectedEntities(updatedEntities);
      dispatch(setEntity(updatedEntities));
      setEntitiesText('');
    }
  };

  if (!entity.isIncluded) {
    return (
      <div className="grid grid-cols-5 gap-x-9">
        <div className="col-span-2">
          <Paragraph className="text-base-l font-medium">
            {t('segment.entity.chooseEntity')}
          </Paragraph>

          <div className="flex flex-col justify-between items-center mt-2 mb-6 gap-5">
            <Select
              value={entityType}
              className="gap-2"
              suffixIcon={<ChevronDown />}
              onChange={(value) => setEntityType(value)}
              options={keywordEntityTypes}
            />
            <div className="flex gap-2 self-start">
              <Checkbox
                checked={positiveChecked}
                onChange={(e) => setPositiveChecked(e.target.checked)}
              >
                <div className="text-xs">Positive</div>
              </Checkbox>
              <Checkbox
                checked={negativeChecked}
                onChange={(e) => setNegativeChecked(e.target.checked)}
              >
                <div className="text-xs">Negative</div>
              </Checkbox>
            </div>
          </div>

          <TextArea
            className="h-[300px] py-3 pb-10 pt-3"
            placeholder={t('segment.entity.enterEntity')}
            value={entitiesText}
            onChange={(event) => setEntitiesText(event.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="col-span-3">
          <div className="w-full shrink-0 grow gap-3">
            <EntityTagsList
              tagsItems={selectedEntities}
              isIncluded={entity?.isIncluded}
              onClearAll={handleClearAll}
              onClear={handleClear}
              onSentimentChange={handleSentimentChange}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-5 gap-x-9">
      <div className="col-span-2">
        <Paragraph className="text-base-l font-medium">
          {t('segment.entity.chooseEntity')}
        </Paragraph>
        <div className="flex flex-col justify-between items-center mt-2 mb-6 gap-5">
          <Select
            value={entityType}
            className="gap-2"
            suffixIcon={<ChevronDown />}
            onChange={(value) => setEntityType(value)}
            options={keywordEntityTypes}
          />
          <div className="flex gap-2 self-start">
            <Checkbox
              checked={positiveChecked}
              onChange={(e) => setPositiveChecked(e.target.checked)}
            >
              <div className="text-xs">Positive</div>
            </Checkbox>
            <Checkbox
              checked={negativeChecked}
              onChange={(e) => setNegativeChecked(e.target.checked)}
            >
              <div className="text-xs">Negative</div>
            </Checkbox>
          </div>
        </div>

        <TextArea
          className="h-[130px] py-3 pb-10 pt-3"
          placeholder={t('segment.entity.enterEntity')}
          value={entitiesText}
          onChange={(event) => setEntitiesText(event.target.value)}
          onKeyDown={handleKeyDown}
        />

        <Input
          className="w-full mt-10 border border-gray-300"
          placeholder={t('pages.management.searchPlaceholder')}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <div className="flex flex-col mt-6">
          {isLoadingSuggestedEntities ? (
            <Skeleton active />
          ) : suggestedEntities && Object.keys(suggestedEntities.keywords).length === 0 ? (
            <Paragraph className="self-center">{t('segment.entity.noData')}</Paragraph>
          ) : (
            <div className="overflow-y-auto h-[180px]">
              {suggestedEntities &&
                Object.keys(suggestedEntities.keywords).map((key) => (
                  <div key={key} className="flex items-center mb-2">
                    <Checkbox
                      checked={selectedEntities
                        .map((item) => item.keyword)
                        .includes(key + ' - ' + suggestedEntities.keywords[key])}
                      onChange={() =>
                        handleCheckboxChange(key + ' - ' + suggestedEntities.keywords[key])
                      }
                      className="mr-2"
                    />
                    <label>{key + ' - ' + suggestedEntities.keywords[key]}</label>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="col-span-3">
        <div className="w-full shrink-0 grow gap-3">
          <EntityTagsList
            tagsItems={selectedEntities}
            isIncluded={entity?.isIncluded}
            onClearAll={handleClearAll}
            onClear={handleClear}
            onSentimentChange={handleSentimentChange}
          />
        </div>
      </div>
    </div>
  );
};
