import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from '../../services/logService';
import { getTagWrapToolState } from './tagWrapToolSelectors';
import { tagWrappingToolApi } from './tagWrappingToolApi';
import {
  TagWrappingToolSingleRequest,
  TagWrappingToolSingleResponse,
} from './tagWrappingToolApiTypes';
import {
  wrapSingleTagFail,
  wrapSingleTagStart,
  wrapSingleTagSuccess,
} from './tagWrappingToolSlice';
import { TagWrappingToolState } from './types';

function* wrapSingleTagSaga() {
  try {
    const singleTagWrapToolState: TagWrappingToolState = yield select(getTagWrapToolState);

    const requestData: TagWrappingToolSingleRequest = {
      adContent: singleTagWrapToolState.adContent,
      clickUrl: singleTagWrapToolState.clickUrl,
      advertiserUrl: singleTagWrapToolState.advertiserUrl,
      dsp: singleTagWrapToolState.dsp,
      advertiserId: singleTagWrapToolState.advertiserId,
    };

    const response: TagWrappingToolSingleResponse = yield call(
      tagWrappingToolApi.getSingleWrappedTag,
      requestData,
    );

    yield put(wrapSingleTagSuccess(response));
  } catch (e) {
    notifyError(e);

    yield put(wrapSingleTagFail({ message: i18next.t('tagWrappingTool.errors.failed') }));
  }
}

export function* tagWrappingToolSagas() {
  yield all([takeLatest(wrapSingleTagStart.type, wrapSingleTagSaga)]);
}
