import {
  AccuracyThresholdTextValues,
  Geo,
  SegmentStatus,
  TrackingTags,
} from 'modules/segment/types';

import { SegmentLabel } from '../labels/types';

export enum TargetCombinationsTypes {
  keywords = 'keywords',
  categories = 'categories',
  sentiment = 'sentiment',
  languages = 'languages',
  keywordEntities = 'keywordEntities',
  customCategories = 'customCategories',
  emotions = 'emotions',
  intentPrompt = 'intentPrompt',
}

export type SegmentListItem = {
  id: string;
  name: string;
  ssp: string;
  dsp: string;
  dspSeatId: string | null;
  sspDealId: string | null;
  expiredDate: string;
  impressions: number;
  status: SegmentStatus;
  labels: SegmentLabel[];
  teamId: string;
  teamName: string;
  companyId: string | null;
  async: boolean;
  group: string | null;
};

export type SegmentDetailsResponse = {
  id: string;
  name: string;
  domainInclusionListId: string;
  domainExclusionListId: string;
  sspSegmentId: string | null;
  sspSeatId: string | null;
  dspSeatId: string | null;
  sspDealId: string | null;
  userId: string;
  companyId: string;
  parentId: string | null;
  group: string | null;
  hideNameForPartner: boolean;
  teamId: string;
  dsp: string;
  ssp: string;
  status: SegmentStatus;
  targetCombinations: TargetCombinationsResponse[];
  countries?: [];
  realtime: boolean;
  async: boolean;
  viewability: number;
  ctr: number;
  brandImpressionTime: number;
  dwellTime: number;
  conversionRate: number;
  timeInView: number;
  attentionMetric: number;
  pageQuality: number;
  screenRealEstate: number;
  createdDate: string;
  modifiedDate: string;
  expiredDate: string;
  debugUntil: string;
  description: string | null;
  impressionTag: string | null;
  sensitiveSubjects: string[];
  tags: TrackingTags;
  labels: SegmentLabel[];
  accuracyThreshold: AccuracyThresholdTextValues;
  publicSegment: boolean;
};

export type TargetCombinationsTargetEmotion = {
  name: string;
  weight: number;
};

export type TargetCombinationsKeywordEntities = {
  emotion: TargetCombinationsTargetEmotion[];
  entity_type: string;
  keyword: string;
  sentiment_max: number;
  sentiment_min: number;
};

export type TargetCombinationsTarget = {
  categories: string[];
  createdDate: string;
  customCategories: string[];
  emotions: string[];
  exclude: boolean;
  hash: string;
  id: string;
  keywordEntities: TargetCombinationsKeywordEntities[];
  keywords: string[];
  languages: string[];
  sentiment: {
    min: number;
    max: number;
  };
  type: TargetCombinationsTypes;
  vectors: number[][];
  vectorsInput: string[][];
};

export type TargetCombinationsResponse = {
  createdDate?: string;
  hash?: string;
  id?: string;
  targets: TargetCombinationsTarget[];
};

export type PerformanceMetrics = {
  ctr?: number;
  viewability?: number;
  attentionMetric?: number;
  attentionMetricSlider?: number;
  timeInView?: number;
};

export interface KeywordEntityGroup {
  keywordEntities: {
    keyword: string;
    entity_type: string;
    sentiment_max: number;
    sentiment_min: number;
  }[];
  exclude: boolean;
}

export interface KeywordGroup {
  keywords: string[];
  exclude: boolean;
  intentPrompt: boolean;
}

export interface CategoryGroup {
  categories: string[];
  exclude: boolean;
}

export interface EmotionGroup {
  emotions: string[];
  exclude: boolean;
}

export interface SentimentGroup {
  sentiment: {
    min: number;
    max: number;
  };
  exclude: boolean;
}

type TargetCombinationsElement =
  | KeywordGroup
  | KeywordEntityGroup
  | CategoryGroup
  | EmotionGroup
  | SentimentGroup;

export interface TargetCombinations extends Array<TargetCombinationsElement> {}

export interface BriefUploadResponse {
  filters: {
    timeRange: string;
    geo: Geo[];
  };
  segmentId: string;
  targetCombinations: TargetCombinations[];
  sensitiveSubjects: [];
  viewability: number;
  ctr: number;
  attentionMetric: number;
  timeInView: number;
  ssp: string;
}
