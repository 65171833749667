import { api } from 'services/api';

import { CTV_SEGMENT_URL_ROOT } from './constants';
import { CtvSegmentRequest, CtvSegmentResponse } from './types';

const saveCtvSegment = (data: CtvSegmentRequest) => {
  return api.post(`${CTV_SEGMENT_URL_ROOT}/`, data);
};

const deleteCtvSegment = (id: string) => {
  return api.delete(`${CTV_SEGMENT_URL_ROOT}/${id}`);
};

const updateCtvSegment = (id: string, data: CtvSegmentRequest) => {
  return api.put<CtvSegmentResponse>(`${CTV_SEGMENT_URL_ROOT}/${id}`, data);
};

const getCtvSegmentById = (id: string) => {
  return api.get(`${CTV_SEGMENT_URL_ROOT}/${id}`);
};

export const ctvSegmentApi = {
  saveCtvSegment,
  deleteCtvSegment,
  updateCtvSegment,
  getCtvSegmentById,
};
