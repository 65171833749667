import React from 'react';

import { OrgSegmentNode } from '../../types';

export const SegmentNodeContent = ({ node }: { node: OrgSegmentNode }) => {
  const active: boolean = node._highlighted || node._upToTheRootHighlighted;

  return (
    <div
      className={`box-border rounded-2xl p-4 bg-scale-impact-back-gradient w-full h-full  ${
        active ? 'border-4 border-secondary-darkPurple' : 'border border-nano-light-purple'
      }`}
    >
      <section className="flex justify-center items-center text-base-xl w-full h-full max-w-full max-h-full text-primary-dark-purple-100">
        {node.type}
      </section>
    </div>
  );
};
