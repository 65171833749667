import { ColumnsType } from 'antd/es/table';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Table, Text } from 'components/common';

import { useCompanyListQuery } from 'modules/adminPanel/company';

import { ROUTES } from 'routing/constants';

import { usePreboundCallback } from 'hooks/usePreboundCallback';

import { GroupingMode, UsersListItem } from '../types';
import { UserListItem } from '../userApiTypes';
import { getIsUserLoading, getUserToDeleteId } from '../userSelectors';
import { setUserToDeleteId } from '../usersSlice';
import { GroupedUserNameRenderer } from './GroupedUserNameRenderer';
import { NoData } from './NoData';
import { UserListActions } from './UserListActions';
import { useUserDelete } from './useUserDelete';
import { useUsersGrouping } from './useUserListGrouping';
import { useTeamsListQuery } from './usersListApiSlice';

type UserTableProps = {
  dataSource: UserListItem[];
  groupingMode: GroupingMode;
  setGroupByCompany: (company: string[]) => void;
};
export const GroupedUserTable = ({
  dataSource,
  groupingMode,
  setGroupByCompany,
}: UserTableProps) => {
  const navigate = useNavigate();
  const navPath = ROUTES.USER_BY_ID;
  const { t } = useTranslation();
  const { data: companies, isLoading: loadingCompanies } = useCompanyListQuery(null);
  const { data: teams, isLoading: loadingTeams } = useTeamsListQuery(null);
  const isLoading = useSelector(getIsUserLoading);
  const userToDeleteId = useSelector(getUserToDeleteId);
  const confirmDelete = useUserDelete();

  const dispatch = useDispatch();
  const { groupUsers } = useUsersGrouping();
  const groupedUsers = groupUsers(dataSource, groupingMode);

  const findCompanyNameById = useMemo(
    () =>
      (companyId: string): string | null => {
        const company = companies?.find((c) => c.id === companyId);

        return company ? company.name : 'N/A';
      },
    [companies],
  );

  const findTeamNameById = useMemo(
    () =>
      (teamId: string | null): string => {
        const team = teams?.find((c) => c.id === teamId);

        return team ? team.name : 'N/A';
      },
    [teams],
  );

  const handleDelete = useCallback(
    (id: string) => {
      dispatch(setUserToDeleteId(id));
      confirmDelete();
    },
    [confirmDelete, dispatch],
  );

  const handleNavigateToEdit = usePreboundCallback((id: string) => {
    navigate(navPath.replace(':id', id), { relative: 'path' });
  });

  const columns: ColumnsType<UsersListItem> = useMemo(
    () => [
      {
        title: t('adminPanel.user.table.name'),
        dataIndex: 'name',
        render: (name, record) => {
          return (
            <GroupedUserNameRenderer
              text={name}
              record={record}
              groupingMode={groupingMode}
              setGroupByCompany={setGroupByCompany}
            />
          );
        },
        showSorterTooltip: false,
        ellipsis: true,
        width: '15%',
      },
      {
        title: t('adminPanel.user.table.email'),
        dataIndex: 'email',
        render: (email) => <Text large>{email}</Text>,
        showSorterTooltip: false,
        ellipsis: true,
        width: '10%',
        onCell: (record) => {
          return record.children ? { colSpan: 0 } : {};
        },
      },
      {
        title: t('adminPanel.user.table.company'),
        dataIndex: 'companyId',
        render: (companyId) => <Text large>{findCompanyNameById(companyId)}</Text>,
        showSorterTooltip: false,
        ellipsis: true,
        width: '10%',
        onCell: (record) => {
          return record.children ? { colSpan: 0 } : {};
        },
      },

      {
        title: t('adminPanel.user.table.team'),
        dataIndex: 'teamId',
        render: (teamId) => <Text large>{findTeamNameById(teamId)}</Text>,
        showSorterTooltip: false,
        ellipsis: true,
        width: '10%',

        onCell: (record) => {
          return record.children ? { colSpan: 0 } : {};
        },
      },

      {
        width: '2%',

        render: ({ id }) => {
          const deleteUser = () => {
            handleDelete(id);
          };

          const displayLoader = id === userToDeleteId;
          const getUser = handleNavigateToEdit(id);

          return (
            <UserListActions
              onUserEdit={getUser}
              onUserDelete={() => deleteUser()}
              isLoading={isLoading}
              displayLoader={displayLoader}
            />
          );
        },

        onCell: (record) => {
          return record.children ? { colSpan: 0 } : {};
        },
      },
    ],
    [
      findCompanyNameById,
      findTeamNameById,
      groupingMode,
      handleDelete,
      handleNavigateToEdit,
      isLoading,
      setGroupByCompany,
      t,
      userToDeleteId,
    ],
  );

  return (
    <>
      <Table
        className="nano-segments-table nano-grouped-table"
        columns={columns}
        dataSource={groupedUsers}
        locale={{ emptyText: <NoData /> }}
        rowKey="id"
        loading={isLoading || loadingCompanies || loadingTeams}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showTotal: (total, [min, max]) => (
            <>
              {min} - {max} of {total}
            </>
          ),
          locale: { items_per_page: '' },
        }}
        expandable={{
          defaultExpandAllRows: true,
          expandedRowKeys: groupedUsers.map((item) => item.key),
        }}
      />
    </>
  );
};
