export const CTV_SEGMENT_URL_ROOT = '/app-segment';
export const CTV_SEGMENT_LIST_URL = `${CTV_SEGMENT_URL_ROOT}/all`;

export const INTEGRATION_TYPE_MANAGED = 'managed';
export const INTEGRATION_TYPE_DATA_ONLY = 'dataOnly';

export const PARTNER_EQUATIV = {
  name: 'Equativ',
  key: 'EQ',
  defaultPricingGroupName: 'Custom',
};
export const PARTNER_PUBMATIC = {
  name: 'PubMatic',
  key: 'PM',
  defaultPricingGroupName: 'Default',
};
export const PARTNER_XANDR = {
  name: 'Xandr',
  key: 'Xandr',
};
export const PARTNER_COMMERCEGRID = {
  name: 'CommerceGrid',
  key: 'MG',
  defaultPricingGroupName: 'Default',
};
export const PARTNER_INDEXEXCHANGE = {
  name: 'IndexExchange',
  key: 'IE',
  defaultPricingGroupName: 'Default',
};

export const usedPartners = [
  PARTNER_EQUATIV,
  // PARTNER_PUBMATIC,
  // PARTNER_XANDR,
  // PARTNER_COMMERCEGRID,
  // PARTNER_INDEXEXCHANGE,
];
