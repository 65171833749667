import {
  CTV_TARGETING_TYPE_APP,
  CTV_TARGETING_TYPE_GENRE,
  CTV_TARGETING_TYPE_INTENT_PERSONA,
  CTV_TARGETING_TYPE_PLATFORM,
  CtvTargetCombinationForApi,
  CtvTargetCombinationState,
  CtvTargetingTypes,
  CtvTargetState,
} from '../types';

export const createKeyTypeForCtvTargetData = (ctvTargetType: CtvTargetingTypes) => {
  switch (ctvTargetType) {
    case CTV_TARGETING_TYPE_GENRE:
      return 'genres';
    case CTV_TARGETING_TYPE_INTENT_PERSONA:
      return 'intentPersonas';
    case CTV_TARGETING_TYPE_APP:
      return 'apps';
    case CTV_TARGETING_TYPE_PLATFORM:
      return 'platforms';
  }
};

export const formatCtvTargetCombinationsForApi = (
  ctvTargetingCombinations: CtvTargetCombinationState[],
): CtvTargetCombinationForApi[] => {
  return ctvTargetingCombinations
    .filter((ctvTC) => ctvTC.targets.length > 0)
    .map((ctvTargetingCombination) => {
      return {
        id: ctvTargetingCombination.id,
        targets: ctvTargetingCombination.targets
          .filter((target) => filterIndividualTarget(target))
          .map((target) => ({
            ...formatIndividualTarget(target),
          })),
      };
    });
};

const formatIndividualTarget = (target: CtvTargetState) => {
  if (target.type === CTV_TARGETING_TYPE_GENRE && target.genres && target.genres.length > 0) {
    return {
      id: target.id,
      exclude: target.exclude,
      type: target.type,
      genres: target.genres.map((obj) => obj.id),
    };
  }
  if (target.type === CTV_TARGETING_TYPE_APP && target.apps && target.apps.length > 0) {
    return {
      id: target.id,
      exclude: target.exclude,
      type: target.type,
      apps: target.apps.map((obj) => obj.id),
    };
  }
  if (
    target.type === CTV_TARGETING_TYPE_PLATFORM &&
    target.platforms &&
    target.platforms.length > 0
  ) {
    return {
      id: target.id,
      exclude: target.exclude,
      type: target.type,
      platforms: target.platforms.map((obj) => obj.id),
    };
  }
  if (
    target.type === CTV_TARGETING_TYPE_INTENT_PERSONA &&
    target.intentPersonas &&
    target.intentPersonas.length > 0
  ) {
    return {
      id: target.id,
      exclude: target.exclude,
      type: target.type,
      intentPersonas: target.intentPersonas.map((obj) => obj.id),
    };
  }

  return {
    id: target.id,
    exclude: target.exclude,
    type: target.type,
  };
};

const filterIndividualTarget = (target: CtvTargetState) => {
  return (
    (target.type === CTV_TARGETING_TYPE_GENRE && target.genres && target.genres.length > 0) ||
    (target.type === CTV_TARGETING_TYPE_APP && target.apps && target.apps.length > 0) ||
    (target.type === CTV_TARGETING_TYPE_PLATFORM &&
      target.platforms &&
      target.platforms.length > 0) ||
    (target.type === CTV_TARGETING_TYPE_INTENT_PERSONA &&
      target.intentPersonas &&
      target.intentPersonas.length > 0)
  );
};

export const validateTargetCombinationsForApi = (
  ctvTargetingCombinations: CtvTargetCombinationState[],
) => {
  if (ctvTargetingCombinations.length > 0) {
    return ctvTargetingCombinations.some((ctvTargetingCombination) => {
      if (ctvTargetingCombination.targets.length > 0) {
        return ctvTargetingCombination.targets.some((target) => {
          return (
            (target.genres && target?.genres.length > 0) ||
            (target.platforms && target?.platforms.length > 0) ||
            (target.apps && target?.apps.length > 0) ||
            (target.intentPersonas && target?.intentPersonas.length > 0)
          );
        });
      }

      return false;
    });
  }

  return false;
};
