import { Copy } from 'feather-icons-react';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  CopyableTextArea,
  Button,
  Field,
  Text,
  Spin,
  Tooltip,
  IconButton,
} from 'components/common';

import { trackingTagsFieldNames } from 'modules/segment/constants';
import { getSegment } from 'modules/segment/segmentSelectors';

interface TrackingTagsFormData {
  impression: string;
  viewability: string;
  click: string;
  general: string;
  start: string;
  pause: string;
  resume: string;
  firstQuartile: string;
  midPoint: string;
  thirdQuartile: string;
  complete: string;
  mute: string;
  unmute: string;
  fullscreen: string;
}

const SegmentTrackingTagsForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  const { tags, isLoading } = useSelector(getSegment);
  const { standard, video } = tags || {};

  const { control, setValue, getValues } = useForm<TrackingTagsFormData>({
    defaultValues: {
      impression: '',
      viewability: '',
      click: '',
      general: '',
      complete: '',
      firstQuartile: '',
      fullscreen: '',
      midPoint: '',
      mute: '',
      pause: '',
      resume: '',
      start: '',
      thirdQuartile: '',
      unmute: '',
    },
  });

  useEffect(() => {
    if (tags && Object.keys(tags).length > 0 && !isLoading) {
      const transformedTagsResponse = {
        ...tags.standard,
        ...tags.video,
      };

      [...trackingTagsFieldNames.standard, ...trackingTagsFieldNames.video].forEach((name) => {
        setValue(
          name as keyof TrackingTagsFormData,
          transformedTagsResponse[name as keyof TrackingTagsFormData],
        );
      });
    }
  }, [tags, isLoading, standard, video, setValue]);

  const getUrlFromTag = useCallback((tag: string) => {
    if (tag !== undefined) {
      const tagHtml: RegExpMatchArray | null = tag.match(/<img.*?src=["|'](.*?)["|']/);

      if (tagHtml) {
        return tagHtml.map((x) => x.replace(/.*src="([^"]*)".*/, '$1'))[0];
      } else {
        return '';
      }
    } else {
      return '';
    }
  }, []);

  const getAllVideoTrackingUrl = useCallback(() => {
    const getTagUrl = (tagName: string) =>
      getUrlFromTag(getValues()[tagName as keyof TrackingTagsFormData]);

    const tagNames = [
      'start',
      'pause',
      'resume',
      'firstQuartile',
      'midPoint',
      'thirdQuartile',
      'complete',
      'mute',
      'unmute',
      'fullscreen',
    ];

    const urlCopyAll = tagNames
      .map(
        (tagName) => `${tagName.charAt(0).toUpperCase() + tagName.slice(1)}: ${getTagUrl(tagName)}`,
      )
      .join('\n');

    navigator.clipboard.writeText(urlCopyAll);
  }, [getUrlFromTag, getValues]);

  return (
    <>
      <form className="h-[500px] pr-6 overflow-y-auto">
        {!isLoading ? (
          <div>
            <div>
              <Text large className="font-semibold mb-3 text-text-100">
                {t('trackingTags.sections.general.title')}
              </Text>
              <div className="grid grid-cols-2 gap-5 my-8">
                {trackingTagsFieldNames.standard.map((name) => (
                  <Field
                    key={name}
                    label={t(`trackingTags.sections.general.${name}`)}
                    name={name}
                    control={control}
                    render={({ field, fieldState: { invalid } }) => (
                      <CopyableTextArea
                        readOnly
                        className="min-h-[200px] text-text-60 pr-10"
                        invalid={invalid}
                        {...field}
                      />
                    )}
                  />
                ))}
              </div>
            </div>
            <div>
              <Text large className="font-semibold text-text-100">
                {t('trackingTags.sections.videoTracking.title')}
                <Tooltip trigger="click" placement="top" title={t('trackingTags.copied')}>
                  <IconButton
                    size="small"
                    type="button"
                    variant="text"
                    onClick={getAllVideoTrackingUrl}
                  >
                    <Copy size={20} />
                  </IconButton>
                </Tooltip>
              </Text>
              <div className="grid grid-cols-2 gap-5 my-8">
                {trackingTagsFieldNames.video.map((name) => (
                  <Field
                    key={name}
                    label={t(`trackingTags.sections.videoTracking.${name}`)}
                    name={name}
                    control={control}
                    render={({ field, fieldState: { invalid } }) => (
                      <CopyableTextArea
                        readOnly
                        className="min-h-[200px] text-text-60 pr-10"
                        invalid={invalid}
                        {...field}
                      />
                    )}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex h-full w-full justify-center items-center">
            <Spin />
          </div>
        )}
      </form>
      <div className="mt-8 pr-10 flex items-center justify-end">
        <Button type="button" variant="primary" onClick={onClose}>
          {t('pages.segmentDetails.close')}
        </Button>
      </div>
    </>
  );
};

export default SegmentTrackingTagsForm;
