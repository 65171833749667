import { Tag as TagAntd, TagProps as TagAntdProps } from 'antd';
import classNames from 'classnames';
import { X } from 'feather-icons-react';

export type EntityTagProps = {
  variant: 'green' | 'red' | string;
  className?: string;
} & TagAntdProps;

export const EntityTag = ({ variant, children, className, ...rest }: EntityTagProps) => (
  <TagAntd
    closeIcon={<X size={16} />}
    bordered={false}
    className={classNames('nano-tag', variant, className)}
    {...rest}
  >
    <div className="w-full flex flex-row items-center justify-between gap-2">{children}</div>
  </TagAntd>
);
