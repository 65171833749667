import React from 'react';

import { OrgDomainNode } from '../../types';

export const DomainNodeContent = ({ node }: { node: OrgDomainNode }) => {
  const active: boolean = node._highlighted || node._upToTheRootHighlighted;

  return (
    <div className="flex flex-col justify-center items-center h-full w-full rounded-2xl overflow-y-scroll">
      <div className="bg-secondary-white h-full w-full rounded-2xl ">
        <div
          className={`rounded-2xl ${
            active
              ? 'border-4 border-secondary-darkPurple p-3'
              : 'border border-nano-light-purple p-4'
          } box-border bg-nano-pca-teal bg-opacity-50 w-full h-full flex flex-row justify-start items-center`}
        >
          <section className="max-w-full max-h-full text-base-l text-primary-dark-purple-100 whitespace-normal break-words w-full overflow-y-scroll">
            {node.name}
          </section>
        </div>
      </div>
    </div>
  );
};
