export const PDF_FILE_MIME_TYPE = 'application/pdf';
export const DOC_FILE_MIME_TYPE = 'application/msword';
export const XLSX_FILE_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const DOCX_FILE_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const PPT_FILE_MIME_TYPE = 'application/vnd.ms-powerpoint';
export const PPTX_FILE_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation';

export const KEYWORDS_FILE_TEMPLATE_URL = 'https://dt6xkzcrd91j4.cloudfront.net/template.xlsx';

export const KEYWORDS_LIMIT = 5000;

export const INTENT_PROMPT_LIMIT = 50;

export const keywordEntityTypes = [
  { value: 'GENERAL', label: 'General' },
  { value: 'PERSON', label: 'Person' },
  { value: 'ORG', label: 'Organization' },
  { value: 'PRODUCT', label: 'Product' },
  { value: 'EVENT', label: 'Event' },
  { value: 'GPE', label: 'Countries, cities, states.(Geopolitical entity)' },
  { value: 'FAC', label: 'Buildings, airports, highways, bridges, etc.' },
  { value: 'NORP', label: 'Nationalities or religious or political groups.' },
  { value: 'WOA', label: 'Work Of Art' },
  { value: 'LOC', label: 'Non-GPE locations, mountain ranges, bodies of water.' },
];
