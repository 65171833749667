import { combineReducers } from '@reduxjs/toolkit';

import { dialog } from 'components/dialog/dialogSlice';

import { nanoAdminPanelApi } from 'modules/adminPanel/adminPanelApiSlice';
import { adminPanelReducers } from 'modules/adminPanel/adminPanelReducers';
import { auth } from 'modules/auth/authSlice';
import { emotionDistribution } from 'modules/charts/EmotionDistribution/EmotionDistributionSlice';
import { geoLanguages } from 'modules/charts/GeoLanguages/GeoLanguagesSlice';
import { intentConfirmation } from 'modules/charts/IntentConfirmation/IntentConfirmationSlice';
import { intentSeasonality } from 'modules/charts/IntentSeasonality/IntentSeasonalitySlice';
import { scaleImpact } from 'modules/charts/ScaleImpact/scaleImpactSlice';
import { sentimentDistribution } from 'modules/charts/SentimentDistribution/SentimentDistributionSlice';
import { totalReachEstimation } from 'modules/charts/TotalReachEstimation/totalReachEstimationSlice';
import { labels } from 'modules/labels/labelsSlice';
import { nanoBot } from 'modules/nanoBot/nanoBotSlice';
import { emptySegment } from 'modules/segment/emptySegment/emptySegmentSlice';
import { segment } from 'modules/segment/segmentSlice';
import { teams } from 'modules/teams/teamsSlice';

import { advertiser } from '../modules/advertiser/advertiserSlice';
import { ctvSegmentReducer } from '../modules/ctv/ctvSegmentSlice';
import { ctvTargetCombinationsReducer } from '../modules/ctv/targeting/ctvTargetCombinationsSlice';
import { ctvTargetReducer } from '../modules/ctv/targeting/ctvTargetSlice';
import { intentEstimateSliceReducer } from '../modules/intentEstimateFlowDiagram/IntentEstimateSlice';
import { pixel } from '../modules/pixel/pixelSlice';
import { tagWrappingToolReducers } from '../modules/tagWrappingTool/tagWrappingToolSlice';
import { nanoApi } from './rootApiSlice';

export const createRootReducer = () => {
  return combineReducers({
    auth,
    dialog,
    segment,
    emotionDistribution,
    geoLanguages,
    emptySegment,
    sentimentDistribution,
    intentSeasonality,
    intentConfirmation,
    nanoBot,
    totalReachEstimation,
    scaleImpact,
    labels,
    teams,
    advertiser,
    pixel,
    [nanoApi.reducerPath]: nanoApi.reducer,
    ...adminPanelReducers,
    [nanoAdminPanelApi.reducerPath]: nanoAdminPanelApi.reducer,
    ...tagWrappingToolReducers,
    ...intentEstimateSliceReducer,
    ctvSegmentReducer,
    ctvTargetReducer,
    ctvTargetCombinationsReducer,
  });
};
